.plus {
    color: #b0db43;
}

.minus {
    color: #ff6978;
}

.question {
    color: #bebbbb;
}
