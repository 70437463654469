@use 'sass:map';
@import 'src/styles/variables/font';

@mixin config-font($size, $weight: 0) {
    font-size: map.get($font-size, $size);
    line-height: map.get($line-height, $size);

    @if $weight != 0 {
        font-weight: $weight;
    }
}

/* font-xs */

@mixin font-xs {
    @include config-font(xs);
}

@mixin font-xs-400 {
    @include config-font(xs, 400);
}

@mixin font-xs-500 {
    @include config-font(xs, 500);
}

@mixin font-xs-600 {
    @include config-font(xs, 600);
}

/* font-s */

@mixin font-s {
    @include config-font(s);
}

@mixin font-s-400 {
    @include config-font(s, 400);
}

@mixin font-s-500 {
    @include config-font(s, 500);
}

@mixin font-s-600 {
    @include config-font(s, 600);
}

/* font-m */

@mixin font-m {
    @include config-font(m);
}

@mixin font-m-400 {
    @include config-font(m, 400);
}

@mixin font-m-500 {
    @include config-font(m, 500);
}

@mixin font-m-600 {
    @include config-font(m, 600);
}

/* font-l */

@mixin font-l {
    @include config-font(l);
}

@mixin font-l-400 {
    @include config-font(l, 400);
}

@mixin font-l-500 {
    @include config-font(l, 500);
}

@mixin font-l-600 {
    @include config-font(l, 600);
}

/* font-xl */

@mixin font-xl {
    @include config-font(xl);
}

@mixin font-xl-400 {
    @include config-font(xl, 400);
}

@mixin font-xl-500 {
    @include config-font(xl, 500);
}

@mixin font-xl-600 {
    @include config-font(xl, 600);
}

/* font-xxl */

@mixin font-xxl {
    @include config-font(xxl);
}

@mixin font-xxl-400 {
    @include config-font(xxl, 400);
}

@mixin font-xxl-500 {
    @include config-font(xxl, 500);
}

@mixin font-xxl-600 {
    @include config-font(xxl, 600);
}
