@import '/src/styles/variables/color';
@import '/src/styles/mixins/font';

.root {
    cursor: pointer;
    border-radius: 10px;
    background-color: rgba(#9381ff, 0.05);
    padding: 12px 16px;
    border: 1px solid rgba(#9381ff, 0.4);
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 10px;
}

.title {
    margin-right: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include font-m-500;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.line {
    display: flex;
    align-items: center;
    gap: 14px;
    @include font-s;
}

.lineIcon {
    @include font-xs;
    font-size: 10px;
    color: #bccce0;
}
