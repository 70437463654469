@import '/src/styles/variables/color';
@import '/src/styles/mixins/font';

.root {
}

.title {
    margin-bottom: 12px;
    font-weight: 600;
}

.list {
    display: flex;
    flex-direction: column;
    gap: 4px;
    list-style: none;
    padding-left: 16px;
}

.firstName {
    margin-right: 5px;
    @include font-s;
}

.lastName {
    margin-right: 10px;
    @include font-s;
}

.userName {
    @include font-s;
    color: grey;
}
