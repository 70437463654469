@import 'src/styles/variables/screen';

.root {
    margin-left: auto;
    margin-right: auto;
    min-width: $SCREEN_MOBILE_MIN_WIDTH;
    max-width: $SCREEN_MOBILE_MAX_WIDTH;
    padding-left: $SCREEN_MOBILE_OFFSET;
    padding-right: $SCREEN_MOBILE_OFFSET;
}
