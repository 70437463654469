@import '/src/styles/variables/color';
@import '/src/styles/mixins/font';

.root {
    padding-top: 24px;
    padding-bottom: 24px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.titleWrapper {
    margin-right: 32px;
}

.title {
    @include font-s;
}

.meetName {
    @include font-l-600;
    margin-right: 20px;
}

.editIcon {
    color: #9381ff;
}

.spinner {
    display: flex;
    justify-content: center;
}

.content {
}

.main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
    margin-left: 6px;
}

.line {
    display: flex;
    align-items: center;
    gap: 14px;
}

.lineIcon {
    @include font-xs;
    color: #bccce0;
}

.status {
    margin-bottom: 40px;
}

.statusText {
    margin-left: 6px;
}
