@import '/src/styles/variables/color';
@import '/src/styles/mixins/font';

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $COLOR_WHITE;
    @include font-m-400;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
