@import '/src/styles/variables/color';
@import '/src/styles/mixins/font';

.root {
    padding-top: 24px;
    padding-bottom: 24px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
}

.title {
}

.spinner {
    display: flex;
    justify-content: center;
    padding-top: 60px;
}
